
export default {
  name: "SupportSection",
  props: ['zone'],
  data() {
    return {
      logos: [
        {src: '/images/logos/wordpress_monotone_black.svg', altKey: 'supportSection.logoWordPress'},
        {src: '/images/logos/magento_monotone_black.svg', altKey: 'supportSection.logoMagento'},
        {src: '/images/logos/squarespace_monotone_black.svg', altKey: 'supportSection.logoSquarespace'},
        {src: '/images/logos/woocomerce_monotone_black.svg', altKey: 'supportSection.logoWooCommerce'},
        {src: '/images/logos/shopify_monotone_black.svg', altKey: 'supportSection.logoShopify'}
      ],
      integrationLogos: [
        {src: '/images/logos/integrations/activecampaign.svg', altKey: 'supportSection.integrationActiveCampaign', width: 516, height: 53},
        {src: '/images/logos/integrations/mailchimp.svg', altKey: 'supportSection.integrationMailchimp', width: 185, height: 60},
        {src: '/images/logos/integrations/webhook.svg', altKey: 'supportSection.integrationWebhook', width: 159, height: 44},
        {src: '/images/logos/integrations/zapier.svg', altKey: 'supportSection.integrationZapier', width: 80, height: 40},
      ],
    };
  },
  computed: {
    doubleIntegrationLogos() {
      return [...this.integrationLogos, ...this.integrationLogos];
    },
  },
};
